<script setup lang="ts">
import { type ComputedRef, type Ref, type WritableComputedRef } from 'vue'
import _ from 'lodash'
import { useAuthStore } from '@/store/auth/auth'
import { type ICompany } from '@/store/companies/types/companyTypes'
import { type ISearchableCompanyFields } from '@/repository/modules/company/types/companyTypes'
import { useSelectedCompanyForWholeAppStore } from '~/store/selectedCompanyForWholeApp/selectedCompanyForWholeApp'
import { useSelectedCompanyForWholeAppStatus } from '~/composables/selectedCompanyForWholeApp/fetch/selectedCompanyForWholeAppStatus'
import { Status } from '~/constants/general/Status'

const authStore = useAuthStore()
const selectedCompanyStore = useSelectedCompanyForWholeAppStore()
const searchInputOnAutocomplete: Ref<string> = useState<string>(() => '')

const selectedCompanyStatus = useSelectedCompanyForWholeAppStatus()

const isLoadingApiFetch: ComputedRef<boolean> = computed((): boolean => selectedCompanyStatus.selectedCompanyForWholeAppStatus.value === Status.LOADING)
const loadedFoundCompanies: ComputedRef<Array<ICompany>> = computed((): Array<ICompany> => selectedCompanyStore.getFoundCompanies())

const firstSearch: Ref<boolean> = useState(() => true)

const selectedCompanyFromAutocomplete: WritableComputedRef<Nullable<ICompany>> = computed({
  get(): Nullable<ICompany> {
    return selectedCompanyStore.getSelectedCompany()
  },
  set(company: Nullable<ICompany>) {
    selectedCompanyStore.setSelectedCompany(company)
  },
})

let controller: AbortController = new AbortController()
let signal: AbortSignal = controller.signal

watch(
  searchInputOnAutocomplete,
  _.debounce(() => {
    if (!controller.signal.aborted && !firstSearch.value) {
      controller.abort()
      firstSearch.value = false
    } else {
      controller = new AbortController()
      signal = controller.signal
    }
    // eslint-disable-next-line no-console
    console.log('searching: ', searchInputOnAutocomplete.value)
    const searchCriteria: ISearchableCompanyFields = {
      Name: searchInputOnAutocomplete.value,
      OnlyMajorCompanies: true,
      Id: null,
    }
    selectedCompanyStore.searchCompanies(searchCriteria, signal)
  }, 500),
)
</script>

<template>
  <v-autocomplete
    v-if="authStore.loggedUserIsAdmin()"
    v-model="selectedCompanyFromAutocomplete"
    :flat="true"
    :hide-details="true"
    :items="loadedFoundCompanies"
    label="Select company"
    prepend-inner-icon="mdi-domain"
    rounded="xl"
    variant="solo-filled"
    :multiple="false"
    :return-object="true"
    :item-title="'name'"
    :loading="isLoadingApiFetch"
    @update:search="(val: string) => (searchInputOnAutocomplete = val)"
  ></v-autocomplete>
</template>

<style scoped lang="sass"></style>
